import 'xops6.css';
import { EntityDescriptor, Utils, apolloClientHolder, FieldDescriptor, EntityTableSimpleRRC, EntityTableSimple } from "@crispico/foundation-react";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import moment from "moment";
import { Button, Checkbox, Form, Icon, Input, Message, Modal, TextArea } from "semantic-ui-react";
import React from "react";
import gql from "graphql-tag";
import { DocumentNode } from "graphql";
import _ from "lodash";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { RRCProps, Reducers, ReduxReusableComponents, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/DateFieldRenderer";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { entityDescriptors } from '@crispico/foundation-react/entity_crud/entityCrudConstants';
import { AssociationFieldEditor } from '@crispico/foundation-react/entity_crud/fieldEditors/AssociationFieldEditor';
import { GanttUtils } from '../GanttUtils';

interface MessageData {
    id: number,
    date: string,
    sender: string,
    message: string,
    lastEditTime: string,
    lastEditBy: string,
    observation: string,
    status?: MessageStatus,
    userName?: string,
    answer?: string,
    driver?: string,
    type?: string,
}

interface SendMessageModal {
    show: boolean,
    comment: string,
    to: { id: number, qualifiedName: string, isDriver: boolean },
    message: string,
    isWarningDisplayed: boolean
}

export class GanttMessagesState extends State {
    data: MessageData[] = [];
    showTableModal: boolean = false;
    refreshMessages: boolean = false;
    showOnlyUnreadMessages: boolean = true;
    sendMessageModal: SendMessageModal = { show: false, isWarningDisplayed: false, comment: "", to: { id: 0, qualifiedName: "", isDriver: false }, message: "" }
    showObservationModal: boolean = false;
    showCloseConfirmationModal: boolean = false;
    observationData: string = "";
    missionIdObservationData: number = 0;
    numberOfUnreadMessages: number = 0;
}

export class GanttMessagesReducers<S extends GanttMessagesState = GanttMessagesState> extends Reducers<S> {
}

type GanttMessagesProps = RRCProps<GanttMessagesState, GanttMessagesReducers> & {
    entities: { [entityName: string | number]: { [id: number]: any } } | undefined,
    driverId?: number,
    removeDriverId: () => void
}

enum MessageStatus { READ = "read", UNREAD = "unread", NO_ANSWER = "No answer" };

const commentFd = new class extends FieldDescriptor { constructor() { super(); this.name = "comment"; this.type = "Comment"; }}
const organizationFd = new class extends FieldDescriptor { constructor() { super(); this.name = "organization"; this.type = "Organization"; }}

export class GanttMessages extends React.Component<GanttMessagesProps> {
    protected saveMission2EventMutation!: DocumentNode;
    protected sendMessageMutation!: DocumentNode;
    protected ganttMessagesEntityDescriptor: EntityDescriptor;
    protected commentQuery!: DocumentNode;
    protected sendMessageRef: React.RefObject<any> = React.createRef();
    protected entityTableSimpleRef: React.RefObject<EntityTableSimple> = React.createRef();

    constructor(props: GanttMessagesProps) {
        super(props);
        const that = this;
        this.ganttMessagesEntityDescriptor = new EntityDescriptor({ name: "GanttMessages" })
            .addFieldDescriptor({ name: "date", type: FieldType.date,
                additionalFieldRendererProps: FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.timeFormat })
            })
            .addFieldDescriptor({ name: "sender", type: FieldType.string })
            .addFieldDescriptor(new class extends FieldDescriptor {
                constructor() {
                    super();
                    this.name = "message";
                    this.type = FieldType.string;
                }

                renderField(entity: any): React.ReactNode {
                    let color = "";
                    let fontWeight: any = '';

                    if (entity.status === MessageStatus.UNREAD) {
                        color = "violet";
                        fontWeight = 'bold';
                    }

                    return <div style={{ fontWeight: fontWeight, color: color }} onDoubleClick={() => that.clickHandlerObservationButton(entity.id)}>{[`${entity.userName}:` + entity.message, <br />,
                        entity.driver + ": " + (entity.status === "No answer" ? "-" : entity.answer), <br />,
                        (entity.type === undefined ? "" : entity.type)]}</div>;
                    }
            })
            .addFieldDescriptor({ name: "lastEditTime", type: FieldType.date,
                additionalFieldRendererProps: FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.timeFormat })
         })
            .addFieldDescriptor({ name: "lastEditBy", type: FieldType.string })
            .addFieldDescriptor(new class extends FieldDescriptor {

                constructor() {
                    super();
                    this.name = "read";
                    this.type = FieldType.string;
                }

                renderField(entity: any): React.ReactNode {
                    return <Checkbox checked={entity.status !== MessageStatus.UNREAD} onClick={ () => that.clickReadHandler(entity) } />;
                }
            })
            .addFieldDescriptor(new class extends FieldDescriptor {
                constructor() {
                    super();
                    this.name = "observation";
                    this.type = FieldType.string;
                }

                renderField(entity: MessageData): React.ReactNode {
                    return <div className='flex-container-row flex-center justify-content-space-between gap5'>
                        {entity.observation}
                        <Button icon="signup" onClick={() => that.clickHandlerObservationButton(entity.id)} /></div>;
                }
            });
        this.processData = this.processData.bind(this);
        this.clickHandlerObservationButton = this.clickHandlerObservationButton.bind(this);
        this.closeMessagesModal = this.closeMessagesModal.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.closeObservationModal = this.closeObservationModal.bind(this);
        this.initQueriesAndMutations();
    }

    protected initQueriesAndMutations() {
        this.saveMission2EventMutation = gql(`
            mutation m($params: SaveParams_LongInput) {
                missionEvent2Service_save(params: $params) {
                    id, status
                }
            }
        `);
        this.sendMessageMutation =  gql(`
            mutation sendMessage($answer: String, $fromMobile: Boolean, $message: String, $userid: Long, $humanResourceId: Long, $username: String) {
                missionEvent2Service_sendMessageToHumanResource(answer: $answer, fromMobile: $fromMobile, message: $message, userid: $userid, humanResourceId: $humanResourceId, username: $username)
            }
        `);
    }

    componentDidMount() {
        this.props.r.setInReduxState({ sendMessageModal: {...this.props.s.sendMessageModal}});
        this.props.r.setInReduxState({ refreshMessages: false });
        this.processData();
    }

    componentDidUpdate(prevProps?: GanttMessagesProps) {
        if (prevProps && (this.props.s.refreshMessages !== prevProps.s.refreshMessages || (prevProps?.driverId !== this.props.driverId))) {
            if (this.props.driverId) {
                const driver = this.props.entities?.HumanResource[this.props.driverId]
                this.props.r.setInReduxState({ sendMessageModal: {...this.props.s.sendMessageModal, to: { id: this.props.driverId, qualifiedName: driver.firstName + ", " + driver.lastName, isDriver: true }}})
            }
            this.processData();
            this.props.r.setInReduxState({ refreshMessages: false })
        }
        if (prevProps?.s.showTableModal !== this.props.s.showTableModal && this.props.s.showTableModal === false) {
            this.props.removeDriverId();
        }
    }

    protected markAllMessagesAsRead(): void {
        if (this.props.s.data) {
            for (let i = 0; i < this.props.s.data.length; i++) {
                if (this.props.s.data[i].status === MessageStatus.UNREAD) {
                    this.clickReadHandler(this.props.s.data[i]);
                }
            }
        }
    }

    public getNumberUnreadMessages(): number {
        let numberUnreadMessages = 0;
        if (this.props.s.data) {
            for (let i = 0; i < this.props.s.data.length; i++) {
                if (this.props.s.data[i].status === MessageStatus.UNREAD) {
                    numberUnreadMessages++;
                }
            }
        }
        return numberUnreadMessages;
    }

    protected async saveMissionData(missionEventId: number, missionUpdates: {}): Promise<void> {
        await apolloClientHolder.apolloClient.mutate({ mutation: this.saveMission2EventMutation,
            variables: { params: { id: missionEventId, fieldsAndValues: missionUpdates } },
            context: { showSpinner: true }
        });
    }

    protected async clickReadHandler(missionEvent: MessageData): Promise<void> {
        let missionUpdates = { "status": missionEvent.status === MessageStatus.UNREAD ? MessageStatus.READ : MessageStatus.UNREAD, "statusChangedAt": Utils.now().getTime() };
        await this.saveMissionData(missionEvent.id, missionUpdates);
    }

    protected processData(): void {
        let data: MessageData[] = [];
        if (this.props.entities) {
            let missions = this.props.entities["Mission2"];
            for (let missionId in missions) {
                const mission = GanttUtils.findByUid(GanttUtils.toEntityUid("Mission2", parseInt(missionId)), this.props.entities);
                if (mission.humanResource && mission.events && mission.events.length > 0) {
                    for (let eventId in mission.events) {
                        const event = mission.events[eventId];
                        if (event.deleted !== false || (this.props.driverId && this.props.driverId !== event.driverId) || (!event.notificationCategory && event.descriptorType !== "DRIVER_MESSAGE")) {
                            continue;
                        }
                        if (event.status) {
                            if (this.props.s.showOnlyUnreadMessages) {
                                if (event.status !== MessageStatus.UNREAD) {
                                    continue;
                                }
                            }
                            const messageProperties: MessageData = {
                                id: event.id,
                                date: moment(event.creationdate).toString(),
                                sender: event.driver !== undefined ? event.driver : event.userName,
                                message: event.message,
                                lastEditTime: !Utils.isNullOrEmpty(event.statusChangedAt) ? moment(event.statusChangedAt).format(Utils.dateTimeFormat) : "",
                                lastEditBy: event.statusChangedBy,
                                observation: event.observation,
                                status: event.status,
                                userName: event.userName,
                                answer: event.answer,
                                driver: event.driver,
                                type: event.type
                            }
                            data = [...data, messageProperties];
                        }
                    }
                    data = data.slice().sort((message1: MessageData, message2: MessageData) => new Date(message1.date).getTime() > new Date(message2.date).getTime() ?  -1 : 1);
                    this.props.r.setInReduxState({ data });
                }
            }
        }
    }

    protected clickHandlerObservationButton(missionEventId: number): void {
        let [missionEvent] = this.props.s.data && this.props.s.data.filter(mission => mission.id === missionEventId) as any;
        this.props.r.setInReduxState({ showObservationModal: true, observationData: missionEvent["observation"], missionIdObservationData: missionEventId });
    }

    protected async closeMessagesModal() {
        this.getNumberUnreadMessages() === 0 ? this.props.r.setInReduxState({ showOnlyUnreadMessages: true, showTableModal: false }) : this.props.r.setInReduxState({ showCloseConfirmationModal: true });
    }

    protected async sendMessage() {
        if (this.props.s.sendMessageModal.message === '') {
            this.props.r.setInReduxState({ sendMessageModal:{ ...this.props.s.sendMessageModal, isWarningDisplayed: true } });
        } else {
            const userId = AppMetaTempGlobals.appMetaInstance.getCurrentUser()?.id;
            const username = AppMetaTempGlobals.appMetaInstance.getCurrentUser()?.username;
            const allHumanResources = this.props.entities?.HumanResource;
            if (this.props.s.sendMessageModal.to.isDriver) {
                    const [firstName, lastName] = this.props.s.sendMessageModal.to.qualifiedName.replace(" ", "").split(",");
                    for (let id in this.props.entities?.HumanResource) {
                        if (firstName === this.props.entities?.HumanResource[Number(id)].firstName && lastName === this.props.entities?.HumanResource[Number(id)].lastName) {
                            await apolloClientHolder.apolloClient.mutate({
                                mutation: this.sendMessageMutation,
                                variables: { answer: "", fromMobile: true, message: this.props.s.sendMessageModal.message, userid: userId, humanResourceId: id, username: username }
                            });
                        } 
                      }
            } else {
                for (const hr in allHumanResources) {
                    if (this.props.s.sendMessageModal.to.qualifiedName === this.props.entities?.HumanResource[Number(hr)].unit?.name) {
                        await apolloClientHolder.apolloClient.mutate({
                            mutation: this.sendMessageMutation,
                            variables: { answer: "", fromMobile: true, message: this.props.s.sendMessageModal.message, userid: userId, humanResourceId: hr, username: username }
                        });
                    }
                }
            }
            this.props.r.setInReduxState({ sendMessageModal: {...this.props.s.sendMessageModal, show: false, isWarningDisplayed: false} });
        }
    }

    protected async closeObservationModal(): Promise<void> {
        let [missionEvent] = this.props.s.data!.filter(mission => mission.id === this.props.s.missionIdObservationData) as any;
        let missionUpdates = { "statusChangedAt": Utils.now().getTime(), "observation": this.props.s.observationData }
        await this.saveMissionData(missionEvent.id, missionUpdates);
        this.props.r.setInReduxState({ showObservationModal: false });
        this.processData();
    }

    protected renderSendMessageModal(): JSX.Element {
        return <ModalExt size="tiny" open={this.props.s.sendMessageModal.show}
            onClose={() => this.props.r.setInReduxState({ sendMessageModal:{ ...this.props.s.sendMessageModal, message: "", comment: "", show: false, isWarningDisplayed: false }})} >
            <Modal.Header><Icon className="envelope outline" />{_msg("GanttMessages.sendMessage.label")}</Modal.Header>
            <Modal.Content className="flex-container">
                <Form className='wh100'>
                    <Form.Field>
                        <label>{_msg("GanttMessages.from.label")}</label>
                        <input type="text" defaultValue={ AppMetaTempGlobals.appMetaInstance.getCurrentUser()?.username }/>
                    </Form.Field>
                    <Form.Field>
                        <label>{_msg("GanttMessages.to.label")}</label>
                        <AssociationFieldEditor fieldDescriptor={organizationFd} innerEntityDescriptor={entityDescriptors[organizationFd.type]} isDisabled={this.props.driverId ? true : false} selectedValue={this.props.s.sendMessageModal.to}
                            onChange={async (value) => value && await this.props.r.setInReduxState({ sendMessageModal: {...this.props.s.sendMessageModal, to: { id: value.id, qualifiedName: value.qualifiedName, isDriver: false }}})}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>{_msg("GanttMessages.comments.label")}</label>
                        <AssociationFieldEditor fieldDescriptor={commentFd} innerEntityDescriptor={entityDescriptors[commentFd.type]}
                           onChange={value => Utils.isNullOrEmpty(value) ? this.props.r.setInReduxState({ sendMessageModal: {...this.props.s.sendMessageModal, message: "", comment: "" }}) : this.props.r.setInReduxState({ sendMessageModal: {...this.props.s.sendMessageModal, message: value.text, comment: value.text} })}
                        />
                    </Form.Field>
                    {this.props.s.sendMessageModal.isWarningDisplayed && <Message negative>
                        <Message.Header>{_msg("GanttMessages.warning.label")}</Message.Header>
                    </Message>}
                    <Form.Field>
                        <label id="message">{_msg("GanttMessages.message.label")}</label>
                        <TextArea rows={10} value={ this.props.s.sendMessageModal.message }
                            onChange={(e, data) => this.props.r.setInReduxState({ sendMessageModal: {...this.props.s.sendMessageModal, message: data.value as string} })}/>
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => this.sendMessage()} >{_msg("general.ok")}</Button>
                <Button onClick={() => this.props.r.setInReduxState({ sendMessageModal:{ ...this.props.s.sendMessageModal, message: "", comment: "", show: false, isWarningDisplayed: false }})} >{_msg("general.close")}</Button>
            </Modal.Actions>
        </ModalExt>
    }

    protected renderObservationModal(): JSX.Element {
        return <ModalExt size="tiny" open={this.props.s.showObservationModal!}
            onMount={() => this.forceUpdate()}
            onClose={this.closeObservationModal} >
            <Modal.Header><Icon className="envelope outline" />{_msg("GanttMessages.message.label")}</Modal.Header>
            <Modal.Content className="flex-container">
                <Input
                    defaultValue={this.props.s.observationData}
                    onChange={(e) => this.props.r.setInReduxState({ observationData: e.target?.value })} />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={this.closeObservationModal} content={_msg("general.ok")} />
                <Button onClick={() => this.props.r.setInReduxState({ showObservationModal: false })} content={_msg("general.cancel")} />
            </Modal.Actions>
        </ModalExt>
    }

    renderCloseConfirmationModal() {
        return (<ModalExt size="tiny" open={this.props.s.showCloseConfirmationModal} onClose={() => this.props.r.setInReduxState({ showCloseConfirmationModal: false })} severity={Severity.CONFIRMATION}>
            <Modal.Content>
                <Modal.Description as={"h3"}>
                    {_msg("GanttMessages.confirmation.label")}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => this.props.r.setInReduxState({ showCloseConfirmationModal: false, showTableModal: false })} primary>{_msg("general.yes")}</Button>
                <Button onClick={() => this.props.r.setInReduxState({ showCloseConfirmationModal: false })} primary>{_msg("general.no")}</Button>
            </Modal.Actions>
        </ModalExt>)
    }

    private getColumns() {
		return [{name: "date", width: 75}, {name: "sender" , width: 75 * 2}, {name: "message", width: 75 * 4}, {name: "lastEditTime", width: 75}, {name: "lastEditBy", width: 125}, {name: "read", width: 75}, {name: "observation", width: 300}];
	}

    render(): JSX.Element {
        return <>
            <ModalExt style={{height: "525px"}} id="modalGanttMessages" size='large' closeIcon open={this.props.s.showTableModal}
                onClose={this.closeMessagesModal} >
                <Modal.Header><Icon className="envelope outline" />{_msg("GanttMessages.messages.label")}</Modal.Header>
                <Modal.Content className="flex-container gap5" style={{height: "75%"}}>
                    <div className="flex-container-row flex-center justify-content-space-between">
                        <Checkbox label={_msg("GanttMessages.showAllMessages.label")} 
                            onChange={async () => { await this.props.r.setInReduxState({ showOnlyUnreadMessages: !this.props.s.showOnlyUnreadMessages }); this.processData() }}/>
                        <div>
                            <Button content={_msg("GanttMessages.markAllAsRead.label")} onClick={() => this.markAllMessagesAsRead()} disabled={this.getNumberUnreadMessages() === 0} />
                            <Button content={_msg("GanttMessages.sendMessage.label")} onClick={async () => {
                                if (this.entityTableSimpleRef.current?.getSelected()) {
                                    const msg = this.props.s.data?.filter(msg => msg.id === this.entityTableSimpleRef.current?.getSelected());
                                    msg[0]?.driver && await this.props.r.setInReduxState({ sendMessageModal: {...this.props.s.sendMessageModal, to: {id: msg[0].id, qualifiedName: msg[0].driver, isDriver: true}} });
                                }
                                this.props.r.setInReduxState({ sendMessageModal: {...this.props.s.sendMessageModal, show: true} })}} />
                        </div>
                    </div>
                    <EntityTableSimpleRRC id="entityTableSimple_ganttMessages" ref={this.entityTableSimpleRef} entityDescriptor={this.ganttMessagesEntityDescriptor} entitiesAsParams={this.props.s.data} columns={this.getColumns()} />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.closeMessagesModal} content={_msg("general.ok")} />
                </Modal.Actions>
            </ModalExt>
            {this.props.s.sendMessageModal.show && this.renderSendMessageModal()}
            {this.props.s.showObservationModal && this.renderObservationModal()}
            {this.props.s.showCloseConfirmationModal && this.renderCloseConfirmationModal()}
        </>
    }
}

export const GanttMessagesRRC = ReduxReusableComponents.connectRRC(GanttMessagesState, GanttMessagesReducers, GanttMessages);