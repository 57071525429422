import { EntityDescriptor, Utils } from "@crispico/foundation-react";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityFilterMode } from "@crispico/foundation-react/entity_crud/fieldEditors/FilterFieldEditor";
import { Chart, ChartConfig, ChartTab, ChartTabProps, ChartTabReducers, ChartTabState } from "../ChartTab";
import { RRCProps, ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Button, Icon, Segment, TextArea } from "semantic-ui-react";
import moment from "moment";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import _ from "lodash";

// an entity descriptor like this will possibly be required later to modify parameters
// export const chartHumanResourcePeriodsEntityDescriptor = new EntityDescriptor({ name: "HumanResourcePeriods" }, false)
//     .addFieldDescriptor({ name: "startDate", type: FieldType.date })
//     .addFieldDescriptor({ name: "endDate", type: FieldType.date })
//     .addFieldDescriptor({ name: "humanResourceFilter", type: FieldType.filter, mode: EntityFilterMode.OBJECT, entityDescriptor: entityDescriptors["HumanResource"] })

export interface HumanResourcePeriodsConfig extends ChartConfig {
    humanResourceFilter: Filter;
    timeSpent: number,
    periods: { name: string, interval: string }[],
    dataPoints: { name: string, entityType: string, filter: Filter, pathToMission?: string }[]
}

export class HumanResourcePeriodsState extends ChartTabState {
    humanResourcePeriods = [] as {
        humanResource: { id: number, name: string, identifier: string },
        total: number,
        timeSpent: number,
        periods: {
            period: { name: string, interval: string, count: number },
            dataPoints: { [name: string]: { count: number, timeSpent: number } }
        }[]
    }[];
    initialStartDate = 0 as number;
    initialEndDate = 0 as number;
} 

export class HumanResourcePeriodsReducers<S extends HumanResourcePeriodsState = HumanResourcePeriodsState> extends ChartTabReducers<S> {
    prepareData(p: { savedData: string, config: HumanResourcePeriodsConfig }) {
        this.s.initialStartDate = moment(p.config.startDate).toDate().getTime();
        this.s.initialEndDate = moment(p.config.endDate).toDate().getTime();
        this.s.humanResourcePeriods = JSON.parse(p.savedData);
    }

    // helper methtods for exportData
    sum(arr: number[]) {
        return arr.reduce((s, a) => s + a, 0);
    }
    
    avg(arr: number[]) {
        return this.round(this.sum(arr) / arr.length);
    }
    
    round(number: number) {
        return Math.round((number + Number.EPSILON) * 100) / 100;
    }

    exportData(chart: Chart, config: HumanResourcePeriodsConfig) {
        const rows: any[] = [];
        const firstRow = [];
        firstRow.push(moment(this.s.startDate).format(Utils.dateTimeFormat) + " - " + moment(this.s.endDate).format(Utils.dateTimeFormat));
        config.periods.forEach(period => {
            firstRow.push(period.name + " (" + period.interval + ")");
            for (let i = 0; i < config.dataPoints.length; i ++) {
                firstRow.push("");
            }
        });
        rows.push(firstRow);
        const total: { [key: string]: number[] } = {};
        const secondRow = [];
        secondRow.push(_msg("HumanResourcePeriods.export.name"));
        config.periods.forEach(period => {
            config.dataPoints.forEach(dataPoint => {
                secondRow.push(dataPoint.name);
                total[period.name + "_" + dataPoint.name] = [];
            });
            secondRow.push(_msg("HumanResourcePeriods.export.timeSpent"));
            total[period.name] = [];
        });
        total["workTime"] = [];
        secondRow.push(_msg("HumanResourcePeriods.export.workTime"));
        total["totalCount"] = [];
        secondRow.push(_msg("HumanResourcePeriods.export.totalMissions"));
        total["productivity"] = [];
        secondRow.push(_msg("HumanResourcePeriods.export.productivity"));
        rows.push(secondRow);
        this.s.humanResourcePeriods.forEach(p => {
            const row = [];
            row.push(p.humanResource.identifier + " " + p.humanResource.name);
            p.periods.forEach(period => {
                let timeSpentForPeriod = 0;
                config.dataPoints.forEach(dataPointConfig => {
                    const dataPoint = period.dataPoints[dataPointConfig.name];
                    total[period.period.name + "_" + dataPointConfig.name].push(dataPoint.count);
                    timeSpentForPeriod += dataPoint.timeSpent;
                    row.push(dataPoint.count);
                });
                total[period.period.name].push(timeSpentForPeriod);
                row.push(Utils.formatDuration(timeSpentForPeriod));
            });
            total["workTime"].push(p.timeSpent);
            row.push(Utils.formatDuration(p.timeSpent));
            total["totalCount"].push(p.total);
            row.push(p.total);
            const productivity = this.round(p.total / (p.timeSpent / 3600000));
            total["productivity"].push(productivity);
            row.push(productivity.toFixed(2));
            rows.push(row);
        });
        const totalRow = [];
        const averageRow = [];
        totalRow.push(_msg("HumanResourcePeriods.export.total"));
        averageRow.push(_msg("HumanResourcePeriods.export.average"));
        config.periods.forEach(period => {
            config.dataPoints.forEach(dataPoint => {
                const arr = total[period.name + "_" + dataPoint.name];
                totalRow.push(this.sum(arr));
                averageRow.push(this.avg(arr));
            });
            const arr = total[period.name];
            totalRow.push("");
            averageRow.push(Utils.formatDuration(this.avg(arr)));
        });
        totalRow.push(Utils.formatDuration(this.sum(total["workTime"])));
        averageRow.push("");
        totalRow.push(this.sum(total["totalCount"]))
        averageRow.push("");
        totalRow.push("")
        averageRow.push(this.avg(total["productivity"]));
        rows.push([]);
        rows.push(totalRow);
        rows.push(averageRow);
        Utils.exportToCsv("HumanResourcePeriods_" + chart.name + "_" + moment(this.s.startDate).toISOString(), rows, true);
    }
}
 
type Props = RRCProps<HumanResourcePeriodsState, HumanResourcePeriodsReducers> & ChartTabProps & { config: HumanResourcePeriodsConfig };

export class HumanResourcePeriods extends ChartTab<Props, {}> {
    async generateXlsx() {
        // this currently overrides the general method with the previously implemented client export
        // TODO: replace this csv export with the xlsx export done on the server
        this.props.r.exportData(this.props.entity, this.props.config);
    }

    render() {
        return <>
            {this.renderTopBar(moment(this.props.s.startDate), moment(this.props.s.endDate))}
        </>;
    }
}

export const HumanResourcePeriodsRRC = ReduxReusableComponents.connectRRC(HumanResourcePeriodsState, HumanResourcePeriodsReducers, HumanResourcePeriods);
